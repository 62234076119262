import { useIntl } from 'react-intl'
import {
  Controller,
  type FieldErrors,
  type UseFormSetValue,
  type Control,
  useWatch,
} from 'react-hook-form'
import { useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import MuiDivider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import MuiRadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'

import { type NewRole, type Role } from 'components/role/roleTypes'
import { PortalType, ROLE_NAME_MAX_LENGTH } from '../../commonConstants'
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useEffect } from 'react'
import { SubContentWrapper, SubSubHeader } from 'components/StyledComponents'
import { portalSettingState } from 'state/portalSettingStates'
import { CaseAccess, FeatureAccess } from 'components/role/roleConstants'

type RoleEditFormProps = {
  role: Role | null
  control: Control<NewRole, unknown>
  errors: FieldErrors<NewRole>
  setValue: UseFormSetValue<NewRole>
  existingNames: string[]
}

const RowWrapper = styled(Stack)`
  width: 100%;
  padding: 20px;
`

const Divider = styled(MuiDivider)`
  background-color: #e9ecfc;
`

const Subtitle = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
`

const RadioGroup = styled(MuiRadioGroup)`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
`

const RadioWrapper = styled(FormControlLabel)<{ selected?: string }>`
  background-color: ${({ selected, theme }) =>
    selected ? theme.palette.info.light : theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
  border: ${({ selected, theme }) =>
    selected
      ? `1.5px solid ${theme.palette.primary.main}`
      : `1.5px solid ${theme.palette.divider}`};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  margin: 0px;

  & .MuiFormControlLabel-label {
    flex-grow: 1;
  }
`

const AccessWrapper = styled(Stack)`
  flex-direction: row;
  gap: 16px;
  color: ${({ theme }) => theme.palette.info.light};
  font-size: 14px;
  align-items: center;
`

const ToggleButtonGroup = styled(MuiToggleButtonGroup)`
  gap: ${({ theme }) => theme.spacing(2)};

  & .MuiToggleButtonGroup-grouped {
    font-size: 12px;
    border: 0;
    padding: 2px 12px;
    color: ${({ theme }) => theme.palette.text.primary};

    &.Mui-disabled {
      border: 0;
    }

    &.Mui-selected {
      border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.background.paper};
    }
  }
`

const RoleEditForm: React.FC<RoleEditFormProps> = ({
  role,
  control,
  errors,
  setValue,
  existingNames,
}) => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const accessPortal = useWatch({
    control,
    name: 'platformAccess.web',
  })
  const accessMobileApp = useWatch({
    control,
    name: 'platformAccess.mobile',
  })
  const canBeCaseHandler = useWatch({
    control,
    name: 'assignmentEligibility.cases',
  })

  useEffect(() => {
    if (!accessPortal) {
      setValue('adminSectionAccess.categories', FeatureAccess.NONE)
      setValue('adminSectionAccess.customers', FeatureAccess.NONE)
      setValue('adminSectionAccess.items', FeatureAccess.NONE)
      setValue('adminSectionAccess.members', FeatureAccess.NONE)
      setValue('adminSectionAccess.roles', FeatureAccess.NONE)
      setValue('adminSectionAccess.settings', FeatureAccess.NONE)
      setValue('adminSectionAccess.alerts', FeatureAccess.NONE)
      setValue('adminSectionAccess.contacts', FeatureAccess.NONE)
      setValue('adminSectionAccess.polls', FeatureAccess.NONE)
    }
  }, [accessPortal])

  useEffect(() => {
    if (accessMobileApp) {
      setValue('assignmentEligibility.tasks', true)
    }
  }, [accessMobileApp])

  useEffect(() => {
    if (canBeCaseHandler) {
      setValue('platformAccess.web', true)
    }
  }, [canBeCaseHandler])

  const renderFeatureAccess = (
    name:
      | 'adminSectionAccess.categories'
      | 'adminSectionAccess.customers'
      | 'adminSectionAccess.items'
      | 'adminSectionAccess.members'
      | 'adminSectionAccess.teams'
      | 'adminSectionAccess.roles'
      | 'adminSectionAccess.settings'
      | 'adminSectionAccess.alerts'
      | 'adminSectionAccess.contacts'
      | 'adminSectionAccess.polls',
    featureAccess?: FeatureAccess,
  ): React.ReactNode => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={FeatureAccess.NONE}
        render={({ field }) => (
          <AccessWrapper>
            <ToggleButtonGroup {...field} color="primary">
              <ToggleButton value={FeatureAccess.NONE}>
                {formatMessage({
                  id: 'member_role_detail.access.hidden',
                })}
              </ToggleButton>
              <ToggleButton value={FeatureAccess.READ}>
                {formatMessage({
                  id: 'member_role_detail.access.view',
                })}
              </ToggleButton>
              <ToggleButton value={FeatureAccess.WRITE}>
                {formatMessage({
                  id: 'member_role_detail.access.edit',
                })}
              </ToggleButton>
            </ToggleButtonGroup>
          </AccessWrapper>
        )}
      />
    )
  }

  // const handleCaseAccessChange = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   field: ControllerRenderProps<NewMemberRole, 'caseAccesses'>,
  //   caseAccess: CaseHandling,
  // ): void => {
  //   const copyValue = [...field.value]
  //   if (e.target.checked) {
  //     copyValue.push(caseAccess)
  //   } else {
  //     copyValue.splice(copyValue.indexOf(caseAccess), 1)
  //   }
  //   field.onChange(copyValue)
  // }

  return (
    <Stack width={'100%'} spacing={2}>
      <RowWrapper>
        <FormControl error={!!errors.name} fullWidth>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
              maxLength: ROLE_NAME_MAX_LENGTH,
              validate: (value) => {
                if (existingNames.includes(value.trim().toLowerCase())) {
                  return false
                }

                return true
              },
            }}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                error={!!errors.name}
                required
                size="small"
                label={formatMessage({
                  id: 'member_role_edit_form.label.role_name',
                })}
                variant="outlined"
                fullWidth
              />
            )}
          />

          {errors.name?.type === 'maxLength' && (
            <FormHelperText>
              {formatMessage(
                {
                  id: 'general.error.max_length',
                },
                { max: ROLE_NAME_MAX_LENGTH },
              )}
            </FormHelperText>
          )}
          {errors.name?.type === 'required' && (
            <FormHelperText>
              {formatMessage({
                id: 'general.error.required',
              })}
            </FormHelperText>
          )}
          {errors.name?.type === 'validate' && (
            <FormHelperText>
              {formatMessage({
                id: 'member_role_edit_form.error.name_exists',
              })}
            </FormHelperText>
          )}
        </FormControl>

        <Controller
          name="default"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label={
                <Typography variant="body2">
                  {formatMessage({
                    id: 'member_role_list.label.default_role',
                  })}
                </Typography>
              }
            />
          )}
        />
      </RowWrapper>

      <Divider />

      <RowWrapper>
        <SubSubHeader>
          {formatMessage({
            id: 'member_role_detail.label.case_visibility',
          })}
        </SubSubHeader>

        <Controller
          name="caseAccess"
          control={control}
          defaultValue={CaseAccess.ITEM_BASED}
          render={({ field }) => (
            <RadioGroup
              {...field}
              onChange={(e) => {
                field.onChange(e)
                setValue('caseAccess', e.target.value as CaseAccess)
              }}
            >
              <RadioWrapper
                key={CaseAccess.ALL}
                value={CaseAccess.ALL}
                selected={field.value === CaseAccess.ALL ? 'selected' : ''}
                control={<Radio />}
                labelPlacement="end"
                label={
                  <Stack>
                    <Subtitle>
                      {formatMessage({
                        id: 'member_role_detail.case_visibility.all_cases.title',
                      })}
                    </Subtitle>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_visibility.all_cases.description',
                      })}
                    </Typography>
                  </Stack>
                }
              />
              <RadioWrapper
                key={CaseAccess.ITEM_BASED}
                value={CaseAccess.ITEM_BASED}
                selected={
                  field.value === CaseAccess.ITEM_BASED ? 'selected' : ''
                }
                control={<Radio />}
                labelPlacement="end"
                label={
                  <Stack>
                    <Subtitle>
                      {formatMessage({
                        id: 'member_role_detail.case_visibility.assigned_items.title',
                      })}
                    </Subtitle>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_visibility.assigned_items.description',
                      })}
                    </Typography>
                  </Stack>
                }
              />
              <RadioWrapper
                key={CaseAccess.TASK_BASED}
                value={CaseAccess.TASK_BASED}
                selected={
                  field.value === CaseAccess.TASK_BASED ? 'selected' : ''
                }
                control={<Radio />}
                labelPlacement="end"
                label={
                  <Stack>
                    <Subtitle>
                      {formatMessage({
                        id: 'member_role_detail.case_visibility.assigned_tasks.title',
                      })}
                    </Subtitle>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_visibility.assigned_tasks.description',
                      })}
                    </Typography>
                  </Stack>
                }
              />

              <RadioWrapper
                key={CaseAccess.NONE}
                value={CaseAccess.NONE}
                selected={field.value === CaseAccess.NONE ? 'selected' : ''}
                control={<Radio />}
                labelPlacement="end"
                label={
                  <Stack>
                    <Subtitle>
                      {formatMessage({
                        id: 'member_role_detail.case_visibility.none.title',
                      })}
                    </Subtitle>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_visibility.none.description',
                      })}
                    </Typography>
                  </Stack>
                }
              />
            </RadioGroup>
          )}
        />
      </RowWrapper>

      <Divider />

      <RowWrapper>
        <SubSubHeader>
          {formatMessage({
            id: 'member_role_detail.label.general_access',
          })}
        </SubSubHeader>

        <Stack
          direction={'row'}
          spacing={1}
          width={'100%'}
          marginLeft={2}
          paddingTop={2}
          paddingBottom={1}
        >
          <Stack flexGrow={1}>
            <Controller
              name="platformAccess.web"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  disabled={canBeCaseHandler}
                  control={
                    <Checkbox {...field} checked={field.value} size="small" />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.general_access.can_access_portal',
                      })}
                    </Typography>
                  }
                />
              )}
            />

            <Controller
              name="platformAccess.mobile"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox {...field} checked={field.value} size="small" />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.general_access.can_access_service_app',
                      })}
                    </Typography>
                  }
                />
              )}
            />
          </Stack>

          <Stack flexGrow={1}>
            <Controller
              name="assignmentEligibility.cases"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox {...field} checked={field.value} size="small" />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.general_access.can_be_case_handler',
                      })}
                    </Typography>
                  }
                />
              )}
            />

            <Controller
              name="assignmentEligibility.tasks"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox {...field} checked={field.value} size="small" />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.general_access.can_be_assigned_to_tasks',
                      })}
                    </Typography>
                  }
                />
              )}
            />
          </Stack>
        </Stack>
      </RowWrapper>

      {accessPortal && (
        <>
          <Divider />

          <RowWrapper>
            <SubSubHeader>
              {formatMessage({
                id: 'member_role_detail.label.administration',
              })}
            </SubSubHeader>

            <Stack width={'100%'} spacing={1}>
              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.settings',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(
                  'adminSectionAccess.settings',
                  role?.adminSectionAccess.settings,
                )}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.items',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(
                  'adminSectionAccess.items',
                  role?.adminSectionAccess.items,
                )}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.categories',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(
                  'adminSectionAccess.categories',
                  role?.adminSectionAccess.categories,
                )}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.roles',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(
                  'adminSectionAccess.roles',
                  role?.adminSectionAccess.roles,
                )}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.members',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(
                  'adminSectionAccess.members',
                  role?.adminSectionAccess.members,
                )}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.teams',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(
                  'adminSectionAccess.teams',
                  role?.adminSectionAccess.teams,
                )}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.customers',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(
                  'adminSectionAccess.customers',
                  role?.adminSectionAccess.customers,
                )}
              </SubContentWrapper>

              {portalSetting?.type === PortalType.CITY && (
                <SubContentWrapper direction={'row'} alignItems="center">
                  <Box flexGrow={1}>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.administration.alerts',
                      })}
                    </Typography>
                  </Box>

                  {renderFeatureAccess(
                    'adminSectionAccess.alerts',
                    role?.adminSectionAccess.alerts,
                  )}
                </SubContentWrapper>
              )}

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.contacts',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(
                  'adminSectionAccess.contacts',
                  role?.adminSectionAccess.contacts,
                )}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.polls',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(
                  'adminSectionAccess.polls',
                  role?.adminSectionAccess.polls,
                )}
              </SubContentWrapper>
            </Stack>
          </RowWrapper>
        </>
      )}

      {/* <Divider />

      <RowWrapper>
        <Subtitle>
          {formatMessage({
            id: 'member_role_detail.label.case_handling',
          })}
        </Subtitle>

        <Controller
          name="caseAccesses"
          control={control}
          render={({ field }) => (
            <Stack
              direction={'row'}
              spacing={1}
              width={'100%'}
              marginLeft={2}
              paddingTop={2}
              paddingBottom={1}
            >
              <Stack flexGrow={1}>
                <FormControlLabel
                  value={CaseHandling.CAN_CREATE_CASE}
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => {
                        handleCaseAccessChange(
                          e,
                          field,
                          CaseHandling.CAN_CREATE_CASE,
                        )
                      }}
                      checked={field.value.includes(
                        CaseHandling.CAN_CREATE_CASE,
                      )}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_access.can_create_cases',
                      })}
                    </Typography>
                  }
                />

                <FormControlLabel
                  value={CaseHandling.CAN_DELETE_CASE}
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => {
                        handleCaseAccessChange(
                          e,
                          field,
                          CaseHandling.CAN_DELETE_CASE,
                        )
                      }}
                      checked={field.value.includes(
                        CaseHandling.CAN_DELETE_CASE,
                      )}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_access.can_delete_cases',
                      })}
                    </Typography>
                  }
                />

                <FormControlLabel
                  value={CaseHandling.CAN_EDIT_CASE_TITLE}
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => {
                        handleCaseAccessChange(
                          e,
                          field,
                          CaseHandling.CAN_EDIT_CASE_TITLE,
                        )
                      }}
                      checked={field.value.includes(
                        CaseHandling.CAN_EDIT_CASE_TITLE,
                      )}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_access.can_edit_case_title',
                      })}
                    </Typography>
                  }
                />

                <FormControlLabel
                  value={CaseHandling.CAN_EDIT_CASE_CATEGORIES}
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => {
                        handleCaseAccessChange(
                          e,
                          field,
                          CaseHandling.CAN_EDIT_CASE_CATEGORIES,
                        )
                      }}
                      checked={field.value.includes(
                        CaseHandling.CAN_EDIT_CASE_CATEGORIES,
                      )}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_access.can_edit_case_categories',
                      })}
                    </Typography>
                  }
                />

                <FormControlLabel
                  value={CaseHandling.CAN_UPDATE_CASE_STATUS}
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => {
                        handleCaseAccessChange(
                          e,
                          field,
                          CaseHandling.CAN_UPDATE_CASE_STATUS,
                        )
                      }}
                      checked={field.value.includes(
                        CaseHandling.CAN_UPDATE_CASE_STATUS,
                      )}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_access.can_edit_case_status',
                      })}
                    </Typography>
                  }
                />
              </Stack>
              <Stack flexGrow={1}>
                <FormControlLabel
                  value={CaseHandling.CAN_MAKE_VIDEO_CALL}
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => {
                        handleCaseAccessChange(
                          e,
                          field,
                          CaseHandling.CAN_MAKE_VIDEO_CALL,
                        )
                      }}
                      checked={field.value.includes(
                        CaseHandling.CAN_MAKE_VIDEO_CALL,
                      )}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_access.can_make_video_call',
                      })}
                    </Typography>
                  }
                />

                <FormControlLabel
                  value={CaseHandling.CAN_SEND_COMMENT}
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => {
                        handleCaseAccessChange(
                          e,
                          field,
                          CaseHandling.CAN_SEND_COMMENT,
                        )
                      }}
                      checked={field.value.includes(
                        CaseHandling.CAN_SEND_COMMENT,
                      )}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_access.can_send_comment',
                      })}
                    </Typography>
                  }
                />

                <FormControlLabel
                  value={CaseHandling.CAN_SET_TASK_DEADLINE}
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => {
                        handleCaseAccessChange(
                          e,
                          field,
                          CaseHandling.CAN_SET_TASK_DEADLINE,
                        )
                      }}
                      checked={field.value.includes(
                        CaseHandling.CAN_SET_TASK_DEADLINE,
                      )}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_access.can_set_task_deadline',
                      })}
                    </Typography>
                  }
                />

                <FormControlLabel
                  value={CaseHandling.CAN_UPDATE_TASK_STATUS}
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => {
                        handleCaseAccessChange(
                          e,
                          field,
                          CaseHandling.CAN_UPDATE_TASK_STATUS,
                        )
                      }}
                      checked={field.value.includes(
                        CaseHandling.CAN_UPDATE_TASK_STATUS,
                      )}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.case_access.can_edit_task_status',
                      })}
                    </Typography>
                  }
                />
              </Stack>
            </Stack>
          )}
        />
      </RowWrapper> */}
    </Stack>
  )
}

export default RoleEditForm
