import { type ChangeEvent, useState, useCallback, type MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import { useDropzone } from 'react-dropzone'
import styled from '@mui/material/styles/styled'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import InputBase, { type InputBaseProps } from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send'

import {
  MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD,
  type FILE_INPUT_ACCEPT_TYPE,
  MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD,
  FILE_INPUT_ACCEPT_MAP,
} from 'utils/fileConstants'
import useFileUpload from 'hooks/useFileUpload'
import FileInput from 'components/form/FileInput'
import UploadedFilesPreview from 'components/form/UploadedFilesPreview'
import { type ProcessedFile } from 'utils/fileTypes'

type CommentInputProps = InputBaseProps & {
  formName: string
  maxLength: number
  limit: number
  accept: FILE_INPUT_ACCEPT_TYPE[]
  isSending: boolean
  onSend?: (comment: string, processedFiles: ProcessedFile[]) => Promise<void>
}

const Wrapper = styled(Stack)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.background.paper};
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  overflow: hidden;
`

const CommentInput: React.FC<CommentInputProps> = ({
  formName,
  maxLength,
  limit,
  accept,
  onSend,
  isSending,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const [comment, setComment] = useState('')

  const {
    setSelectedFiles,
    processedFormFiles,
    handleDeleteFile,
    clearProcessedFiles,
    errors,
  } = useFileUpload({
    formName,
    limit,
    toJpg: true,
    maxWidth: MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD,
    maxHeight: MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD,
  })

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      if (acceptedFiles.length > limit) {
        acceptedFiles.length = limit
      }

      setSelectedFiles(acceptedFiles)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept.reduce<Partial<typeof FILE_INPUT_ACCEPT_MAP>>((obj, key) => {
      obj[key] = FILE_INPUT_ACCEPT_MAP[key]
      return obj
    }, {}),
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputText = event.target.value

    setComment(inputText)
  }

  const handleSend = useCallback(async (): Promise<void> => {
    if (onSend) {
      await onSend(comment, processedFormFiles)
      setComment('')
      clearProcessedFiles()
    }
  }, [comment, processedFormFiles])

  const handleInputClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation()
  }

  return (
    <>
      <Wrapper>
        <FileInput
          {...getInputProps()}
          multiple={limit > 1}
          name="file"
          accept={accept.join(',')}
        />
        <InputBase
          {...props}
          multiline
          value={comment}
          onChange={handleChange}
          inputProps={{
            inputComponent: TextareaAutosize,
          }}
          onClick={handleInputClick}
        />

        {processedFormFiles.length > 0 && (
          <Box marginY={1} {...getRootProps()}>
            <UploadedFilesPreview
              processedFormFiles={processedFormFiles}
              onDeleteFile={handleDeleteFile}
              limit={limit}
            />
          </Box>
        )}

        <Divider />

        <Stack direction="row" width="100%" paddingY={0.5}>
          <Box flexGrow={1}>
            <IconButton
              size="small"
              {...getRootProps()}
              aria-label={formatMessage({
                id: 'general.icon_button.add_attachment',
              })}
              color="primary"
            >
              <AttachFileIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <IconButton
            size="small"
            disabled={
              (!comment && processedFormFiles.length === 0) || isSending
            }
            onClick={() => {
              void handleSend()
            }}
            data-testid="send-button"
            aria-label={formatMessage({
              id: 'general.icon_button.send_message',
            })}
            color="primary"
          >
            <SendIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </Wrapper>

      <Stack direction="row">
        <Box flexGrow={1}>
          {errors?.map((error) => (
            <Typography key={error} variant="caption" color={'error'}>
              {error}
            </Typography>
          ))}
        </Box>
        <Box>
          <Typography
            variant="caption"
            color={comment.length > maxLength ? 'error' : grey[500]}
          >
            {comment.length}/{maxLength}
          </Typography>
        </Box>
      </Stack>
    </>
  )
}

export default CommentInput
