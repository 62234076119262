import { useCallback, useState, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useSnackbar } from 'notistack'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import Chip from '@mui/material/Chip'
import InputAdornment from '@mui/material/InputAdornment'
import LoadingButton from '@mui/lab/LoadingButton'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NextWorkIcon from '@mui/icons-material/NextWeek'
import EmailIcon from '@mui/icons-material/Email'
import AddIcon from '@mui/icons-material/Add'
import ItemIcon from '@mui/icons-material/FolderCopy'

import {
  BackButtonGrey,
  FormFieldsWrapper,
  LightSubHeader,
  MainHeader,
  SettingWrapper,
  SubSubHeader,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { hasUnsavedChangesState } from 'state/formStates'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import usePortalSetting from 'hooks/usePortalSetting'
import { type ItemBasic } from 'components/item/itemTypes'
import { EMAIL_MAX_LENGTH, Path, SUPPORT_LOCALE_LABEL } from 'commonConstants'
import {
  type Contact,
  type EmailTemplate,
} from 'components/contact/contactTypes'
import { isValidEmail } from 'utils/stringUtils'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'
import {
  EMAIL_BODY_MAX_LENGTH,
  EMAIL_SUBJECT_MAX_LENGTH,
  FORWARDING_REPLY_EMAIL,
} from 'components/contact/contactConstants'
import MultiLineInput from 'components/form/MultiLineInput'

type ContactFormData = {
  name: string
  email: string
  itemIds: string[]
  ccEmails: string[]
  emailTemplate: EmailTemplate
  preferredLanguage: string
}

const EmailTemplateWrapper = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
`

const TemplateLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  width: 120px;
`

const DisabledText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.disabled};
`

const ContactAddPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const { contactId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const portalSetting = useRecoilValue(portalSettingState)
  const { getLocalizedContent } = usePortalSetting()
  const { sendPostRequest, sendPutRequest } = useApi()
  const [itemsData, setItemsData] = useState<ItemBasic[]>([])
  const [isCcDialogOpen, setIsCcDialogOpen] = useState(false)
  const [ccEmail, setCcEmail] = useState('')
  const { data: contactData } = useSWR<Contact>(
    portalSetting && contactId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/contacts/${contactId}`
      : null,
  )
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues,
    watch,
    setValue,
    trigger,
  } = useForm<ContactFormData>({
    mode: 'onTouched',
  })

  const toEmail = watch('email')
  const ccEmails = watch('ccEmails')

  const onSubmit: SubmitHandler<ContactFormData> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)

          await sendPutRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/contacts/${contactId}`,
            data,
          )

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })
          goTo(Path.CONTACTS_LIST)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting],
  )

  useEffect(() => {
    if (contactData) {
      setValue('name', contactData.name)
      setValue('email', contactData.email)
      setValue('emailTemplate', contactData.emailTemplate)
      setValue('ccEmails', contactData.ccEmails)
      setValue('preferredLanguage', contactData.preferredLanguage)

      setValue(
        'itemIds',
        contactData.items.map((item) => item.id),
      )
    }
  }, [contactData])

  const getItems = useCallback(async (): Promise<void> => {
    try {
      if (portalSetting) {
        const response = await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/contacts:listAssignableItems`,
        )
        const data = await response.json()
        setItemsData(data)
      }
    } catch (error) {
      console.error(error)
    }
  }, [portalSetting])

  useEffect(() => {
    if (portalSetting) {
      void getItems()
    }
  }, [portalSetting])

  const handleGoBack = (): void => {
    goTo(Path.CONTACTS_LIST)
  }

  const shouldDisableSaving = useMemo(
    () => !isValid || Object.keys(errors).length > 0 || isSaving,
    [isValid, errors, isSaving],
  )

  useEffect(() => {
    if (isDirty) {
      setHasUnsavedChanges(isDirty)
    }
  }, [isDirty])

  const handleDeleteCc = (cc: string): void => {
    setValue(
      'ccEmails',
      ccEmails.filter((ccEmail) => ccEmail !== cc),
    )
  }

  const handleOpenAddCcDialog = (): void => {
    setIsCcDialogOpen(true)
  }

  const handleCloseAddCcDialog = (): void => {
    setCcEmail('')
    setIsCcDialogOpen(false)
  }

  const handleAddCcEmail = (): void => {
    const set = new Set([...ccEmails, ccEmail])
    setValue('ccEmails', Array.from(set))
    void trigger('ccEmails')
    handleCloseAddCcDialog()
  }

  const isValidCc = useMemo(() => {
    if (!ccEmail) {
      return false
    }

    return isValidEmail(ccEmail)
  }, [ccEmail])

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height={'100%'} width={'100%'}>
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Stack flexGrow={1} direction="row" spacing={1}>
              <BackButtonGrey
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonGrey>

              <MainHeader>
                {formatMessage({
                  id: 'contact_edit.header',
                })}
              </MainHeader>
            </Stack>

            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              disabled={shouldDisableSaving}
              loading={isSaving}
              color="secondary"
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'contact_edit.subheader.contact_information',
                })}
              </LightSubHeader>

              <Stack spacing={2} flexGrow={1}>
                <FormControl error={!!errors.name}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      maxLength: EMAIL_MAX_LENGTH,
                      required: true,
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        size="small"
                        label={formatMessage({
                          id: 'contact_edit.label.name',
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <NextWorkIcon fontSize="small" color="primary" />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>

                <FormControl error={!!errors.email}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      maxLength: EMAIL_MAX_LENGTH,
                      required: true,
                      validate: (val): boolean => {
                        if (!val) {
                          return true
                        }

                        return isValidEmail(val)
                      },
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        size="small"
                        label={formatMessage({
                          id: 'contact_edit.label.email',
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon fontSize="small" color="primary" />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />

                  {errors.email?.type === 'validate' && (
                    <FormHelperText>
                      {formatMessage({
                        id: 'general.error.validate_email',
                      })}
                    </FormHelperText>
                  )}
                  {errors.email?.type === 'maxLength' && (
                    <FormHelperText>
                      {formatMessage(
                        {
                          id: 'general.error.max_length_email',
                        },
                        { max: EMAIL_MAX_LENGTH },
                      )}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel
                    id="setting-default-language-label"
                    size="small"
                    required
                  >
                    {formatMessage({
                      id: 'portal_edit.label.default_language',
                    })}
                  </InputLabel>
                  <Controller
                    name="preferredLanguage"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                      <Select
                        {...field}
                        required
                        labelId="setting-default-language-label"
                        label={formatMessage({
                          id: 'portal_edit.label.default_language',
                        })}
                        size="small"
                        fullWidth
                        variant="outlined"
                      >
                        {portalSetting?.supportedLanguages.map((localKey) => (
                          <MenuItem key={localKey} value={localKey}>
                            {SUPPORT_LOCALE_LABEL[localKey]}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <FormFieldsWrapper>
                  <SubSubHeader>
                    {formatMessage({
                      id: 'contact_edit.subheader.email_template',
                    })}
                  </SubSubHeader>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'contact_edit.email_template.description',
                    })}
                  </Typography>
                  <EmailTemplateWrapper spacing={1}>
                    <Stack direction="row" spacing={2}>
                      <TemplateLabel>
                        {formatMessage({
                          id: 'contact_edit.email_template.from',
                        })}
                      </TemplateLabel>
                      <DisabledText>{FORWARDING_REPLY_EMAIL}</DisabledText>
                    </Stack>
                    <Divider />
                    <Stack direction="row" spacing={2}>
                      <TemplateLabel>
                        {formatMessage({
                          id: 'contact_edit.email_template.to',
                        })}
                      </TemplateLabel>
                      <DisabledText>{toEmail}</DisabledText>
                    </Stack>
                    <Divider />
                    <Stack direction="row" spacing={2}>
                      <TemplateLabel>
                        {formatMessage({
                          id: 'contact_edit.email_template.cc',
                        })}
                      </TemplateLabel>

                      {ccEmails?.map((cc) => (
                        <Chip
                          key={cc}
                          label={cc}
                          onDelete={() => {
                            handleDeleteCc(cc)
                          }}
                        />
                      ))}
                      <IconButton onClick={handleOpenAddCcDialog} size="small">
                        <AddIcon />
                      </IconButton>
                    </Stack>
                    <Divider />

                    <FormControl error={!!errors.emailTemplate?.title}>
                      <Controller
                        name="emailTemplate.title"
                        control={control}
                        rules={{
                          maxLength: EMAIL_SUBJECT_MAX_LENGTH,
                          required: true,
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            required
                            size="small"
                            label={formatMessage({
                              id: 'contact_edit.email_template.subject',
                            })}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />

                      {errors.emailTemplate?.title?.type === 'required' && (
                        <FormHelperText>
                          {formatMessage({
                            id: 'general.error.required',
                          })}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl error={!!errors.emailTemplate?.body}>
                      <Controller
                        name="emailTemplate.body"
                        control={control}
                        rules={{
                          maxLength: EMAIL_BODY_MAX_LENGTH,
                          required: true,
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                          <MultiLineInput
                            {...field}
                            required
                            maxLength={EMAIL_BODY_MAX_LENGTH}
                            label={formatMessage({
                              id: 'contact_edit.email_template.body',
                            })}
                            variant="outlined"
                            fullWidth
                            rows={7}
                            error={!!errors.emailTemplate?.body}
                            helpMessage={
                              errors.emailTemplate?.body?.type === 'required'
                                ? formatMessage({
                                    id: 'general.error.required',
                                  })
                                : undefined
                            }
                          />
                        )}
                      />
                      {}
                    </FormControl>
                  </EmailTemplateWrapper>
                </FormFieldsWrapper>
              </Stack>
            </Stack>

            <Divider />

            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'contact_edit.subheader.access_preferences',
                })}
              </LightSubHeader>

              <FormControl error={!!errors.itemIds}>
                <InputLabel id="contact-item-label" size="small" required>
                  {formatMessage({
                    id: 'contact_edit.label.items',
                  })}
                </InputLabel>
                <Controller
                  name="itemIds"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      {...field}
                      required
                      multiple
                      labelId="contact-item-label"
                      label={formatMessage({
                        id: 'contact_edit.label.items',
                      })}
                      startAdornment={
                        <InputAdornment position="start">
                          <ItemIcon fontSize="small" color="primary" />
                        </InputAdornment>
                      }
                      input={
                        <OutlinedInput
                          label={formatMessage({
                            id: 'contact_edit.label.items',
                          })}
                        />
                      }
                      size="small"
                      fullWidth
                      variant="outlined"
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => {
                            const selectedItem = itemsData?.find(
                              (item) => item.id === value,
                            )

                            return (
                              <Chip
                                key={value}
                                size="small"
                                label={getLocalizedContent(selectedItem?.names)}
                              />
                            )
                          })}
                        </Box>
                      )}
                    >
                      {itemsData?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox
                            checked={getValues('itemIds')?.includes(item.id)}
                          />
                          <ListItemText
                            primary={getLocalizedContent(item.names)}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Stack>
          </SettingWrapper>
        </Stack>
      </form>

      <Dialog open={isCcDialogOpen} maxWidth="sm">
        <DialogTitle textAlign="center">
          <Stack alignItems="center" spacing={2}>
            <Box textAlign="center">
              {formatMessage({ id: 'contact_edit.cc_email_dialog.header' })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: 400 }} marginTop={2}>
            <TextField
              id="outlined-controlled"
              label={formatMessage({ id: 'contact_edit.label.email' })}
              value={ccEmail}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCcEmail(event.target.value)
              }}
              fullWidth
              size="small"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} width="100%">
            <Button
              onClick={handleAddCcEmail}
              variant="contained"
              fullWidth
              color="primary"
              disabled={!isValidCc}
            >
              {formatMessage({
                id: 'general.button.save',
              })}
            </Button>
            <Button
              onClick={handleCloseAddCcDialog}
              variant="outlined"
              fullWidth
              color="primary"
              autoFocus
            >
              {formatMessage({
                id: 'general.button.close',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ContactAddPage
